/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import { Grid, Button, Select, TextField } from '@material-ui/core';

import { useDataContext } from '../../DataContext';

interface Props {}

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 'auto',
    width: '100%',
  },
  photosRow: {
    marginLeft: '5%',
  },
}));

const UploadForm: React.FC<Props> = () => {
  const classes = useStyles({});
  const [t] = useTranslation();
  const { fileData, setFileData } = useDataContext();
  const [fileUri, setFileUri] = useState<any>();

  const [isUrlFieldTouched, setIsUrlFieldTouched] = useState<boolean>(false);

  const isUrlValid =
    fileData.fileType === 'link' &&
    fileData.file &&
    ['.png', '.jpg', '.jpeg', '.pdf'].some((e) => (fileData.file as string).includes(e));

  const readURL = (file: File) => {
    var reader = new FileReader();
    reader.readAsDataURL(file); // convert to base64 string
    reader.onload = function (e) {
      setFileUri(e.target.result);
    };
  };
  useEffect(() => {
    if (fileData.fileType === 'file' && fileData.file) {
      readURL(fileData.file as File);
    }
  }, [fileData]);
  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.photosRow}>
        <Grid item xs={12}>
          <Select
            native
            value={fileData.fileType}
            onChange={(event) => {
              // @ts-ignore
              setFileData({ fileType: event.target.value });
              setFileUri(null);
            }}
            inputProps={{
              name: 'File type',
              id: 'age-native-simple',
            }}
          >
            <option value={'link'}>URL</option>
            <option value={'file'}>Upload</option>
          </Select>
        </Grid>
        <Grid item xs={10}>
          {fileData.fileType === 'link' && (
            <TextField
              required
              value={fileData.file || ''}
              id="file"
              name="file"
              label="URL"
              helperText={isUrlFieldTouched && !isUrlValid ? t('enterValidUrl') : ''}
              error={isUrlFieldTouched && !isUrlValid}
              fullWidth
              onBlur={() => {
                if (!isUrlFieldTouched) setIsUrlFieldTouched(true);
              }}
              onChange={(event) =>
                setFileData({
                  ...fileData,
                  file: event.target.value ? event.target.value.trim() : '',
                })
              }
              // autoComplete="billing address-line1"
            />
          )}
          {fileData.fileType === 'file' && (
            <>
              <Button variant="contained" component="label">
                {t('uploadFile')}
                <input
                  type="file"
                  name="file"
                  onChange={(event) => setFileData({ ...fileData, file: event.target.files![0] })}
                  style={{ display: 'none' }}
                />
              </Button>
              <br />
              {fileUri && <img width="300" src={fileUri} alt="preview" />}
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UploadForm;
