import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Container, Box } from '@material-ui/core';

import DataContextProvider from 'DataContext';
import PhotoForm from 'components/PhotoForm';

export default function App() {
  const [t] = useTranslation();

  return (
    <DataContextProvider>
      <Container maxWidth="md">
        <Helmet>
          <title>{t('documentTitle')}</title>
        </Helmet>
        <Box my={4}>
          <PhotoForm />
        </Box>
      </Container>
    </DataContextProvider>
  );
}
