import React, { useState, useContext, ReactElement } from 'react';

import { IFileData, IResponse, IUser } from './declarations';

type DataContextType = {
  userData: IUser;
  setUserData: React.Dispatch<React.SetStateAction<IUser>>;

  fileData: IFileData;
  setFileData: React.Dispatch<React.SetStateAction<IFileData>>;

  response: IResponse;
  setResponse: React.Dispatch<React.SetStateAction<IResponse>>;
};

const DataContext = React.createContext<DataContextType>({
  userData: {} as DataContextType['userData'],
  setUserData: (() => {}) as DataContextType['setUserData'],

  fileData: {} as DataContextType['fileData'],
  setFileData: (() => {}) as DataContextType['setFileData'],

  response: {} as DataContextType['response'],
  setResponse: (() => {}) as DataContextType['setResponse'],
});

const DataContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [userData, setUserData] = useState<IUser>({
    email: '',
    password: '',
  });
  const [fileData, setFileData] = useState<IFileData>({ fileType: 'file' });
  const [response, setResponse] = useState<IResponse>({ images: [] });

  const providerObject = {
    userData,
    setUserData,

    fileData,
    setFileData,

    response,
    setResponse,
  } as DataContextType;

  return <DataContext.Provider value={providerObject}>{children}</DataContext.Provider>;
};

export const useDataContext = () => {
  return useContext(DataContext);
};

export default DataContextProvider;
