import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import UserForm from './UserForm';
import UploadForm from './UploadForm';
import Review from './Review';

// import { unstable_renderSubtreeIntoContainer } from 'react-dom'

import { useDataContext } from '../../DataContext';
import { IResponse } from 'declarations';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Thrupny
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    width: '100%',
  },
  layout: {
    width: 'auto',
    position: 'relative',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  logo: {
    width: '103px',
    height: '108px',
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: 10000,
  },
  passAgainButton: {
    top: '60px',
    left: '0px',
  },
  alreadyPassedText: {
    fontSize: '18px',
    textAlign: 'center',
  },
}));

const steps = ['userCredentials', 'uploadPhoto', 'reviewResult'];

const Checkout: React.FC<any> = () => {
  const classes = useStyles({});
  const [t] = useTranslation();

  const { fileData, setResponse } = useDataContext();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleNextPage = async () => {
    if (activeStep === 1) {
      try {
        // handleNext();
        await sendRequest();
      } catch (e) {
        setErrMessage(e.message);
        setIsLoading(false);
        return false;
      }
    }
    handleNext();
  };
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const sendRequest = async () => {
    setIsLoading(true);

    let data;
    if (fileData.fileType === 'link') {
      data = { url: fileData.file };
    } else {
      data = new FormData();
      data.append('file', fileData.file);
    }

    const response: IResponse = (
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_FILE_URL}ml-api/uploads`,
        data,
        headers: {
          'x-api-key': 'QS7NGD7-7PCMWXB-HW9DFJY-SSV92AE',
        },
      })
    ).data;

    setResponse(response);

    setIsLoading(false);
  };

  // const passTheVerficationAgain = () => {
  //   // setFacePhoto({ name: '' })
  //   setActiveStep(1);
  // };

  const checkIfNextStepAllowed = (isFormValid: boolean) => {
    if (isValid !== isFormValid) {
      setIsValid(isFormValid);
    }
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <UserForm onFieldsChange={checkIfNextStepAllowed} />;
      case 1:
        return <UploadForm />;
      case 2:
        return <Review />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={!!errMessage}
        autoHideDuration={6000}
        message={errMessage}
        onClose={() => setErrMessage('')}
      />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <img src="/logo.png" alt="logo" className={classes.logo} />
          <Typography variant="h6" color="inherit" noWrap>
            Thrupny documents recognition
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {t('kycVerification')}
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, i) => (
              <Step key={label}>
                <StepLabel
                  style={{
                    cursor: i === 1 && activeStep === 2 ? 'pointer' : 'auto',
                  }}
                  onClick={() => i === 1 && activeStep === 2 && setActiveStep(1)}
                >
                  {t(label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            <React.Fragment>
              {getStepContent(activeStep)}
              {/* {activeStep === 2 && (
                <Button className={classes.passAgainButton} onClick={passTheVerficationAgain}>
                  {t('passVerificationAgain')}
                </Button>
              )} */}
              <div className={classes.buttons}>
                {activeStep !== 0 && activeStep === steps.length - 1 && (
                  <Button onClick={handleBack} className={classes.button}>
                    {t('back')}
                  </Button>
                )}
                {activeStep < steps.length - 1 && (
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{ marginTop: '20px' }}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                        disabled={!isValid}
                      >
                        {t('next')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
            </React.Fragment>
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
};

export default Checkout;
