import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'translations/en.json';
import it from 'translations/it.json';

const resources = {
  en: { translation: en },
  it: { translation: it },
  us: { translation: en },
};

const lang = localStorage.getItem('lang') || 'en';

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    lng: lang,
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
