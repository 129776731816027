import React from 'react';
import { Typography, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';

import { useDataContext } from '../../DataContext';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    textAlign: 'center',
    color: 'red',
  },
  avatar: {
    height: 'auto',
    width: '100%',
  },
}));

const Review: React.FC<any> = () => {
  const classes = useStyles({});
  // const [t] = useTranslation();

  const { userData, response } = useDataContext();

  return (
    <React.Fragment>
      {/* <Typography className={classes.center} variant="h6" gutterBottom>
        {t('documentsAreVerified')}
      </Typography> */}
      <List disablePadding>
        {response.images.map((file) => (
          <ListItem key={file.path} className={classes.listItem}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={12}>
                <Typography className={classes.center} variant="h6" gutterBottom>
                  Accuracy - {file.accuracy}
                </Typography>
                {userData.email === 'admin@thrupny.co' && (
                  <Typography className={classes.center} variant="h6" gutterBottom>
                    Image class - {file.img_class}
                  </Typography>
                )}
                <Typography className={classes.center} variant="h6" gutterBottom>
                  Document type - {file.doc_type}
                </Typography>
                {/* <Typography className={classes.center} variant="h6" gutterBottom>
                  Words: {file.words}
                </Typography> */}
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6} sm={6}>
                  <a href={file.path} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '100%' }} key={file.path} src={file.path} alt="uploaded" />
                  </a>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <a href={file.figure_path} target="_blank" rel="noopener noreferrer">
                    <img
                      style={{ width: '100%' }}
                      key={file.figure_path}
                      src={file.figure_path}
                      alt="uploaded figure"
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
};

export default Review;
