import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, TextField, makeStyles } from '@material-ui/core';

import { useDataContext } from 'DataContext';

interface Props {
  onFieldsChange: (isFormValid: boolean) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  terms: {
    position: 'absolute',
    bottom: '-50px',
    left: '-10px',
  },
  link: {
    whiteSpace: 'pre-wrap',
  },
  langSelect: {
    bottom: '-10px',
    width: '100%',
  },
}));

const AddressForm: React.FC<Props> = ({ onFieldsChange }) => {
  const classes = useStyles({});
  const [t] = useTranslation();

  const { userData, setUserData } = useDataContext();

  const [isEmailFieldTouched, setIsEmailFieldTouched] = useState<boolean>(false);
  const [isPasswordFieldTouched, setIsPasswordFieldTouched] = useState<boolean>(false);

  const isEmailValid =
    validateEmail(userData.email) &&
    ['admin@thrupny.co', 'demo@thrupny.co'].includes(userData.email);
  const isPasswordValid =
    userData.password === `${userData.email.split('@').slice(0, 1).join('')}12345`;

  const onDataChange = (event: React.SyntheticEvent) => {
    let target = event.target as HTMLInputElement;
    setUserData({
      ...userData,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    const isFormValid = isEmailValid && isPasswordValid;

    onFieldsChange(isFormValid);
    // eslint-disable-next-line
  }, [userData]);

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        {t('userCredentials')}
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={userData.email || ''}
            id="email"
            name="email"
            label="E-mail"
            helperText={isEmailFieldTouched && !isEmailValid ? t('enterValidEmail') : ''}
            error={isEmailFieldTouched && !isEmailValid}
            fullWidth
            onBlur={() => {
              if (!isEmailFieldTouched) setIsEmailFieldTouched(true);
            }}
            onChange={onDataChange}
            // autoComplete="billing address-line1"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={userData.password || ''}
            id="password"
            name="password"
            label={t('password')}
            helperText={isPasswordFieldTouched && !isPasswordValid ? t('enterValidPassword') : ''}
            error={isPasswordFieldTouched && !isPasswordValid}
            fullWidth
            onBlur={() => {
              if (!isPasswordFieldTouched) setIsPasswordFieldTouched(true);
            }}
            onChange={onDataChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const validateEmail = (email: string): boolean => {
  if (!email) return false;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export default AddressForm;
